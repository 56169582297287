import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Cookies from 'universal-cookie';
import * as Fingerprint2 from '@fingerprintjs/fingerprintjs';
import ReCAPTCHA from 'react-google-recaptcha';
import api from '../../services/api';
import './style.scss';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { useAppContext } from '../../context/AppContext';
const schema = Yup.object().shape({
  email: Yup.string().required('O campo usuário é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const { siteConfig } = useAppContext();
  const [isChecked, setIsChecked] = useState(false);
  const [query, setQuery] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('password');
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [usersAvailable, setUsersAvailable] = useState([]);
  const [usersChecked, setUsersChecked] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');

  const recaptchaRef = React.useRef();

  async function getDeviceFingerprint() {
    const options = { excludes: { audio: true, enumerateDevices: true } };

    const components = await Fingerprint2.getPromise(options);
    if (components) {
      const values = components.map(function(component) {
        return component.value;
      });

      return Fingerprint2.x64hash128(values.join(''), 31);
    }

    return null;
  }

  async function handleSubmit({ email, password }) {
    const fingerPrint = await getDeviceFingerprint();

    let captchaToken = null;

    const validacaoRecaptcha = await api.post('/auth/validacao-recaptcha');

    // só habilita captcha nos ambientes oficiais e teste no alpha / beta
    if (!validacaoRecaptcha.data) {
      captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      // Validação passa a ser feita no backend, pois temos ips que são dispensados do recaptcha (internos, por exemplo)
      // if (!captchaToken) {
      //   setMensagem('Por favor, verifique o reCAPTCHA e tente novamente');
      //   return;
      // }
    }

    try {
      setMensagem('');
      setLoading(true);

      const csrf = await api.get('/sanctum/csrf-cookie');

      const response = await api.post('/auth/login', {
        usuario: email,
        senha: password,
        df: fingerPrint,
        captchaToken: captchaToken,
      });

      const { token, error, message } = response.data;

      if (error === 1) {
        setLoading(false);
        setMensagem(message);
      } else {
        // api.defaults.headers.Authorization = `Bearer ${token}`;
        const cookies = new Cookies();
        cookies.set('token', token, {
          domain: `${
            process.env.NODE_ENV === 'development'
              ? 'localhost'
              : siteConfig?.app_url.replace(/^[^.]+\./g, '')
          }`,
        });

        // setAuthenticated(true);
        window.location.replace(`${query || siteConfig?.app_url}`);
      }
    } catch (err) {
      setLoading(false);
      setMensagem(
        err.response.message ||
          'Desculpe, estamos com problema na conexão com o servidor. Tente novamente em alguns minutos...'
      );
    }
  }

  const handleSelectUser = async event => {
    event.preventDefault();

    if (!selectedClient) return setMensagem('Por favor, selecione uma razão');

    setLoading(true);
    setUserCookie(selectedClient);
  };

  const setUserCookie = cliente_id => {
    if (!cliente_id) return;

    setLoading(true);

    const cookies = new Cookies();

    cookies.set('cliente_id', cliente_id, {
      domain: `${
        process.env.NODE_ENV === 'development'
          ? 'localhost'
          : siteConfig?.app_url.replace(/(^\w+:|^)\/\//, '')
      }`,
    });

    window.location.replace(`${query || siteConfig?.app_url}`);
  };

  function handleClick() {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  }

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const foo = params.get('redirect');
    setQuery(foo);
  }, []);

  const searchClients = async isAuthenticated => {
    if (!isAuthenticated) return;

    try {
      const response = await api.get(`/api/ec/minha-conta/cliente`);
      if (response.data.error !== 0) {
        throw new Error(response.data.message);
      }

      const clientes = response.data.clientes;

      if (clientes.length > 1) {
        setUsersAvailable(response.data.clientes);
        setUsersChecked(true);
        setLoading(false);
        return;
      } else {
        if (clientes.length === 1) {
          setUserCookie(clientes[0].id);
          return;
        } else {
          window.location.replace(`${query || siteConfig?.app_url}`);
          return;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const mascaraCpfCnpj = numero => {
    if (numero !== undefined && numero.length === 11) {
      return numero.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    } else if (numero !== undefined) {
      return numero.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  const parseNomeRazao = nomeRazao => {
    if (nomeRazao.length <= 23) {
      return nomeRazao;
    }

    return nomeRazao.slice(0, 23) + '...';
  };

  useEffect(() => {
    searchClients(isAuthenticated);
  }, [isAuthenticated]);

  return (
    <>
      <div>
        {siteConfig?.app_loginscreen_title ? (
          <span>{siteConfig?.app_loginscreen_title}</span>
        ) : (
          <span>Login do cliente</span>
        )}
        <br />
        <br />
        {!usersChecked ? (
          <Form className="form" schema={schema} onSubmit={handleSubmit}>
            <label htmlFor="email">CNPJ ou usuário</label>
            <Input
              name="email"
              placeholder="Seu CNPJ ou usuário"
              disabled={loading}
            />
            <label htmlFor="password">Senha</label>
            <div className="icons">
              <Input
                className="input-icon"
                name="password"
                type={type}
                autoComplete="current-password"
                required
                placeholder="Digite sua senha"
                disabled={loading}
              />
              <button
                type="button"
                className="button-icon"
                onClick={handleClick}
              >
                {type === 'text' ? (
                  <FaEyeSlash size={15} />
                ) : (
                  <FaEye size={15} />
                )}
              </button>
            </div>
            <div>
              <Toggle checked={isChecked} onChange={handleToggle} />
              <label className="label-conectado">Mantenha-me conectado</label>
              <small>
                <a href="/definir-senha">
                  <b>Esqueci minha senha</b>
                </a>
              </small>
            </div>
            <span>{mensagem}</span>
            <div style={{ margin: '10px auto' }}>
              {!!siteConfig?.app_recaptcha_key_v2 && (
                <ReCAPTCHA
                  sitekey={siteConfig?.app_recaptcha_key_v2}
                  badge="bottomright"
                  ref={recaptchaRef}
                  size="invisible"
                  hl={'pt-BR'}
                />
              )}
            </div>
            <button disabled={loading} type="submit">
              {loading ? 'Carregando...' : 'Entrar'}
            </button>
            <Link to="/cadastro">
              Não tem uma conta? <b>Cadastre-se</b>
            </Link>
          </Form>
        ) : (
          <form className="form" onSubmit={e => handleSelectUser(e)}>
            <label htmlFor="cliente_id">Por favor, selecione uma razão</label>
            {usersAvailable.length ? (
              <select
                name="cliente_id"
                className="selectInput"
                id="cliente_id"
                defaultValue={'0'}
                onChange={e => setSelectedClient(e.target.value)}
              >
                <option value="0">Selecionar...</option>
                {usersAvailable.map(item => {
                  return (
                    <option key={item.id} value={item.id}>
                      {mascaraCpfCnpj(item.cpfcnpj)} -{' '}
                      {parseNomeRazao(item.nomerazao)}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input className="form" placeholder="Carregando..." disabled />
            )}

            <span>{mensagem}</span>

            <button disabled={loading} type="submit">
              {loading ? 'Carregando...' : 'Entrar'}
            </button>
            {/* <Link to="/" onClick={setUsersChecked(false)}>
              Fazer login com outro usuário
            </Link> */}
          </form>
        )}
      </div>
    </>
  );
}
