/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import StepLayout from '../../../pages/_layouts/step';
import { FaArrowLeft, FaEye } from 'react-icons/fa';

const schema = Yup.object().shape({
  nomerazao: Yup.string().required('Por favor, informe a razão'),
  fantasia: Yup.string().required('Por favor, informe o nome'),
  check: Yup.boolean(),
  rgie: Yup.string().when('check', check => {
    if (!check) {
      return Yup.string()
        .required('Por favor, informe a inscrição estadual')
        .matches(
          /.*[0-9]+.*/,
          'O campo inscrição estadual aceita somente números'
        );
    } else {
      return Yup.string().required('Por favor, informe a inscrição estadual');
    }
  }),
  cep: Yup.string()
    .required('Por favor, informe o CEP')
    .matches(/.*[0-9]+.*/, 'O campo CEP aceita somente números'),
  logradouro: Yup.string().required('Por favor, informe o endereço'),
  numero: Yup.string().required('Por favor, informe o número'),
  bairro: Yup.string().required('Por favor, informe o bairro'),
  uf: Yup.string()
    .required('Por favor, informe a UF')
    .matches(/^[aA-zZ\s]+$/, 'O campo UF aceita somente letras')
    .max(2, 'Insira somente a sigla como por exemplo: MG'),
  cidade: Yup.string().required('Por favor, informe a cidade'),
});

const Step4 = props => {
  // const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(false);
  const [disabled, setDisabled] = useState(props.userData.check || false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState(props.userData.rgie);
  const [number, setNumber] = useState(props.userData.numero || 'S/N');
  const [cep, setCep] = useState(props.userData.cep);
  const [adress, setAdress] = useState(props.userData.endereco);
  const [state, setState] = useState(props.userData.estado);
  const [district, setDistrict] = useState(props.userData.bairro);
  const [complement, setComplement] = useState(
    props.userData.complemento || ''
  );

  const { userData, currentStep, onSetUserData, jumpToStep } = props;

  useEffect(() => {
    if (userData.dadosinseridos == 2 && userData.rgie == null) {
      setShow(true);
    }
    if (userData.dadosinseridos == 2 && userData.rgie != null) {
      setShow(false);
    }
    if (userData.dadosinseridos != 2 && userData.rgie == null) {
      setShow(true);
    }
    if (userData.rgie == 'ISENTO') {
      setShow(true);
    }
  });

  const handleSubmit = formData => {
    userData.complemento = complement;
    userData.estado = state;
    userData.endereco = adress;
    onSetUserData(formData);
    // Jump to the next step if everything is okay
    jumpToStep(currentStep + 1);
  };

  const onGoBackClick = () => {
    jumpToStep(currentStep - 1);
  };

  const handleInputChange = () => {
    setDisabled(!disabled);
    if (!disabled) {
      setData('ISENTO');
    }
  };

  return (
    <StepLayout>
      <div>
        <span className="wizard-header">
          <button type="button" className="button-back" onClick={onGoBackClick}>
            <FaArrowLeft />
            Voltar
          </button>
        </span>
        <div className="step step3">
          <div className="row">
            <h2>Empresa</h2>
            <div className="subtitle">
              <p>
                Verifique se todos os dados da empresa estão corretos e complete
                o que estiver faltando.
              </p>
              <p>
                Caso alguma informação esteja incorreta, entre em contato
                conosco.
              </p>
            </div>
            <Form
              className="form signup-form"
              schema={schema}
              onSubmit={handleSubmit}
            >
              <div className="form-item">
                <label>Razão social da empresa*</label>
                <Input
                  name="nomerazao"
                  value={userData.nome}
                  readOnly={
                    !userData.nome || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  inputMode="text"
                />
              </div>
              <div className="form-item">
                <label>Nome fantasia*</label>
                <Input
                  name="fantasia"
                  value={userData.nome}
                  readOnly={
                    !userData.nome || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  inputMode="text"
                />
              </div>
              <div className="form-item">
                <label>Inscrição Estadual*</label>
                <Input
                  name="rgie"
                  value={data}
                  onChange={e => setData(e.target.value)}
                  disabled={disabled}
                  readOnly={!show}
                  inputMode="decimal"
                />
                {show ? (
                  <label>
                    <Input
                      className="input-check"
                      name="check"
                      type="checkbox"
                      value={disabled}
                      checked={disabled}
                      onChange={handleInputChange}
                    />
                    Isento
                  </label>
                ) : null}
              </div>

              {/* <div className="form-item-horizontal"> */}
              <div className="form-item">
                <label>Endereço*</label>
                <Input
                  name="logradouro"
                  value={userData.endereco || adress}
                  readOnly={
                    !userData.endereco || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  onChange={e => setAdress(e.target.value)}
                  inputMode="text"
                />
              </div>

              <div className="form-item">
                <label>Bairro*</label>
                <Input
                  name="bairro"
                  onChange={e => setDistrict(e.target.value)}
                  value={district}
                  readOnly={
                    !userData.bairro || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  inputMode="text"
                />
              </div>

              <div className="form-item">
                <label>CEP*</label>
                <Input
                  name="cep"
                  onChange={e => setCep(e.target.value)}
                  value={cep}
                  readOnly={
                    !userData.cep || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  inputMode="decimal"
                />
              </div>

              <div className="form-item" style={{ width: 0 }}>
                <label>Número*</label>
                <Input
                  name="numero"
                  onChange={e => setNumber(e.target.value)}
                  value={number}
                  readOnly={
                    !userData.numero || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  inputMode="decimal"
                />
              </div>
              {/* </div> */}

              <div className="form-item">
                <label>Complemento</label>
                <Input
                  name="complemento"
                  value={userData.complemento}
                  readOnly={
                    !userData.complemento || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  onChange={e => setComplement(e.target.value)}
                  inputMode="text"
                />
              </div>

              <div className="form-item">
                <label>Cidade*</label>
                <Input
                  name="cidade"
                  value={userData.cidade}
                  readOnly={
                    !userData.cidade || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  inputMode="text"
                />
              </div>

              <div className="form-item" style={{ width: 0 }}>
                <label>Estado*</label>
                <Input
                  name="uf"
                  value={userData.estado || state}
                  readOnly={
                    !userData.estado || userData.dadosinseridos === 2
                      ? false
                      : true
                  }
                  onChange={e => setState(e.target.value)}
                  inputMode="text"
                />
              </div>

              <div className="wizard-footer">
                <button type="submit">Próximo</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

Step4.propTypes = {
  userData: PropTypes.isRequired,
  jumpToStep: PropTypes.func,
  onSetUserData: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

Step4.defaultProps = {
  jumpToStep: () => {},
};

export default Step4;
